<template>
  <IOSSNumbersTableUser
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"

      @filter="filter"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @reloadTable="filter"
  />
</template>

<script>
  import IOSSNumbersTableUser from "./IOSSNumbersTableUser/IOSSNumbersTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {IOSSNumbersMixin} from "../../../../../mixins/IOSSNumbersMixins/IOSSNumbersMixin";

  export default {
    name: "IOSSNumbersTable",

    components: {
      IOSSNumbersTableUser,
    },

    mixins: [routeFilter, IOSSNumbersMixin],

    watch: {
      loadUserAuthorizedData() {
        if(!this.isAdmin) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    },

    data(){
      return {
        forPage: this.$store.getters.getIOSSNumbersForPage,
        page: 1,
        onePage: 1,

        filterIossId: '',
        filterCountryId: '',
        filterMarketplace: '',
        filterComment: '',
        filterNumber: '',

        countFilterParams: 0,
        filterGetParams: {},

        createData: null,
      }
    },

    mounted() {

      if(this.loadUserAuthorizedData && !this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          iossId: this.$route.query.iossId,
          countryId: this.$route.query.countryId,
          marketplace: this.$route.query.marketplace,
          comment: this.$route.query.comment,
          number: this.$route.query.number,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      resetFilter() {
        this.$router.push({path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextIOSSNumbersPage', true)

        // save filter params in store
        this.$store.commit('setIOSSNumbersFilter', window.location.search)

        this.$store.dispatch('fetchIOSSNumbersWithPermissions', url).then(() => {
          // skip get next page
          this.$store.commit('setNextIOSSNumbersPage', false)
        })

        this.checkCountFilter(['userName', 'complaintTypeName', 'reasonsName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

        console.log(this.filterStatus);
        this.generateFilterQueryParams(
            myQuery,
            {
              filterIossId: 'iossId',
              filterCountryId: 'countryId',
              filterMarketplace: 'marketplace',
              filterComment: 'comment',
              filterNumber: 'number',
            },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },
    }
  }
</script>

<style scoped>

</style>
