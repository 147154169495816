var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-filter-wrap"},[(_vm.$store.getters.getIOSSNumbers.length > 0 && _vm.$store.getters.getIOSSNumbersLoading !== true)?[_c('div',{staticClass:"site-table-wrap products-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'payPalCases_id',
          'iossNumbers_category',
          'iossNumbers_country',
          'iossNumbers_marketplace',
          'iossNumbers_comment',
          'iossNumbers_number',
          'common_manage',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t('payPalCases_id.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('iossNumbers_category.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('iossNumbers_country.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('iossNumbers_marketplace.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('iossNumbers_comment.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('iossNumbers_number.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('common_manage.localization_value.value')))])])]),_c('tbody',_vm._l((_vm.$store.getters.getIOSSNumbers),function(item,index){return _c('tr',{key:index},[_c('td',[_c('DefaultCheckbox',{staticClass:"empty-label mt-1",attrs:{"selectedNow":_vm.selectedNow,"dataValue":item.id}})],1),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{staticClass:"table-link btn-style",attrs:{"to":_vm.$store.getters.GET_PATHS.mainSettingsIOSSNumbers + '/show/' + item.id}},[_vm._v(" #"+_vm._s(item.id)+" ")])],1)]),_c('td',[_vm._v(" "+_vm._s(item.category)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.country.currentTranslate.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.marketplace)+" ")]),_c('td',[_c('ValueHelper',{attrs:{"value":item,"deep":'comment'}})],1),_c('td',[_vm._v(" "+_vm._s(item.number)+" ")]),_c('td',[_c('div',{staticClass:"table-right table-manage-list table-manage-list--small",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'common_react',
              'common_edit',
              ])}}}),_c('ManagerButton',{key:_vm.$store.getters.getGlobalUpdateManagerButton,on:{"reloadManagerBtn":function($event){_vm.$store.commit('setGlobalUpdateManagerButton', Math.random())}}},[_c('template',{slot:"item"},[(_vm.isAdmin)?_c('LinkButton',{attrs:{"value":_vm.$t(`common_edit.localization_value.value`),"type":'edit',"link":_vm.$store.getters.GET_PATHS.mainSettingsIOSSNumbers + '/edit/' + item.id}}):_vm._e(),_c('LinkButton',{attrs:{"value":_vm.$t('common_delete.localization_value.value'),"type":'delete'},nativeOn:{"click":function($event){return _vm.removeItem(item.id)}}})],1)],2)],1)])])}),0)]):_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getIOSSNumbers),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('IOSSNumbersTableMobile',{attrs:{"item":item,"index":index,"selectedNow":_vm.selectedNow},on:{"removeItem":_vm.removeItem}})],1)}),0)]),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getIOSSNumbersCommonList.next_page_url !== null && _vm.$store.getters.getIOSSNumbers.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextIOSSNumbersPage},attrs:{"count":_vm.$store.getters.getIOSSNumbersCommonList.total - _vm.$store.getters.getIOSSNumbersForPage * _vm.$store.getters.getIOSSNumbersCommonList.current_page < _vm.$store.getters.getIOSSNumbersForPage ?
                  _vm.$store.getters.getIOSSNumbersCommonList.total - _vm.$store.getters.getIOSSNumbersForPage * _vm.$store.getters.getIOSSNumbersCommonList.current_page:
                  _vm.$store.getters.getIOSSNumbersForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)])])]:_vm._e(),(_vm.$store.getters.getIOSSNumbersLoading === false && _vm.$store.getters.getIOSSNumbers.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }