<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item">
        <DefaultInput
            :label="'Id'"
            :type="'text'"
            v-model="iossId"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['express_Country'])"></span>

        <DefaultSelect
            :options="optionsCountries"
            :label="$t('common_country.localization_value.value')"
            :selected="getCountrySelected()"
            :optionsLabel="'label'"
            @change="changeCountry"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('iossNumbers_marketplace.localization_value.value')"
            :type="'text'"
            v-model="marketplace"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('common_comment.localization_value.value')"
            :type="'text'"
            v-model="comment"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('iossNumbers_number.localization_value.value')"
            :type="'text'"
            v-model="number"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../../../services/filterCountries";

  export default {
    name: "IOSSNumbersFilter",
    components: {
      DefaultSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        iossId: this.filterGetParams.iossId ? this.filterGetParams.iossId : '',
        countryId: this.filterGetParams.countryId ? this.filterGetParams.countryId : '',
        marketplace: this.filterGetParams.marketplace ? this.filterGetParams.marketplace : '',
        comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',
        number: this.filterGetParams.number ? this.filterGetParams.number : '',

        options: [],
        optionsCountries: [],

        filterCounts: [
          'iossId',
          'countryId',
          'marketplace',
          'comment',
          'number',
        ],
      }
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        filterCountriesUSA(countries)
        this.optionsCountries = countries;
        this.getCountrySelected()
      })
    },

    watch: {
      filterGetParams(newVal) {
        this.iossId = newVal.iossId ? newVal.iossId : ''
        this.countryId = newVal.countryId ? newVal.countryId : ''
        this.marketplace = newVal.marketplace ? newVal.marketplace : ''
        this.comment = newVal.comment ? newVal.comment : ''
        this.number = newVal.number ? newVal.number : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading)
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      getCountrySelected(){
        if(this.countryId === '') return ''
        return this._.find(this.optionsCountries, {id: parseInt(this.countryId)})
      },

      changeCountry(val) {
        this.countryId = val.id
      },

    },
  }
</script>

<style scoped>

</style>
