
export const IOSSNumbersMixin = {

  methods: {

    save(edit = false) {

      if(!this.IOSSNumber.firstValidationUser()) return

      let data = this.IOSSNumber.prepareSave(edit)

      let saveType = 'createIOSSNumbers'

      if (edit) {
        data = {
          id: this.IOSSNumber.getId(),
          data: data
        }
        saveType = 'updateIOSSNumbers'
      }

      console.log(data);
      console.log(saveType);

      this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
        console.log(data);

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsIOSSNumbers)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    }
  }

}
